@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400;500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400;500;700;800&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap');

/* For chrome, safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif !important;
}

/* Firebox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mplus {
  font-family: 'M PLUS Rounded 1c', sans-serif !important;

}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.customBanner {
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.customBanner::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  clip-path: polygon(100% 73%, 0% 100%, 100% 100%);
}

/* pagination style start */

.paginationBox {
  display: flex;
  justify-content: center;
  margin: 6vmax;
}

.paginationBox .pagination {
  display: flex;
  justify-content: center;
  padding: 0;
}

.paginationBox .pagination .page-item {
  background-color: #fff;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.178);
  padding: 1vmax 1.5vmax;
  cursor: pointer;
  transition: all 0.3s linear;
}

.page-item:first-child {
  border-radius: 5px 0 0 5px;
}

.page-item:last-child {
  border-radius: 0 5px 5px 0;
}

.page-item .page-link {
  text-decoration: none;
  font: 300 0.7vmax;
  color: rgb(80, 80, 80);
  transition: all 0.3s linear;
}

.paginationBox .pagination .page-item:hover {
  background-color: rgb(230, 230, 230);
  color: red;
}

.paginationBox .pagination .page-item:hover .page-link {
  color: rgb(80, 80, 80);
}

.paginationBox .pagination .pageItemActive {
  background-color: #0e8f99;
}

.paginationBox .pagination .pageItemActive:hover {
  background-color: #0e8f99;
}

.paginationBox .pagination .pageItemActive .pageLinkActive {
  color: #fff;
}

.paginationBox .pagination .pageItemActive:hover .pageLinkActive {
  color: #fff;
}

/* pagination style end */

/* LoginSignUp form Styles start */

.signUpForm {
  transform: translateY(-100%) translateX(-120%);
}

.shiftToLeft {
  transform: translateX(-120%);
}

.shiftToNeutral {
  transform: translateX(0%);
}

.shiftToNeutralForm {
  transform: translateX(0%) translateY(-100%);
}

.shiftToRight {
  transform: translateX(100%);
}

input.avatarChoose::file-selector-button {
  cursor: pointer;
  padding: 0;
  height: 5vh;
  width: 100%;
  border: none;
  z-index: 2;
  margin: 0;
  transition: all 0.5s;
  padding: 0 1vmax;
  color: rgba(0, 0, 0, 0.623);
  background-color: #fff;
}

input.avatarChoose::file-selector-button:hover {
  background-color: rgb(235, 235, 235);
}

/* LoginSignUp form Styles end */

/* user options style in navbar start*/

.MuiFab-root {
  width: 35px !important;
  height: 30px !important;
  border: 2px solid #14cddb !important;
}

/* user options style in navbar ends */

/* Cart page icons style  start*/

.isEmptyCart>svg {
  font-size: 5vmax;
  color: #0e8f99;
}

/* Cart page icons style end*/


.MuiStepConnector-root {
  height: 1px;
  background-color: #0e8f99;
}

.MuiStepLabel-iconContainer-active {
  color: #0e8f99 !important;
}

.MuiStepConnector-active,
.MuiStepConnector-completed {
  background-color: #0e8f99;
}


/* Myorder styles start */

.MuiDataGrid-root {
  color: rgba(0, 0, 0, 0.623);
  font-family: "Poppins", sans-serif;
  box-shadow: 1.7px 1.2px 2.2px rgba(0, 0, 0, 0.02),
    4.1px 2.9px 5.3px rgba(0, 0, 0, 0.028),
    7.8px 5.4px 10px rgba(0, 0, 0, 0.035),
    13.8px 9.6px 17.9px rgba(0, 0, 0, 0.042),
    25.9px 18px 33.4px rgba(0, 0, 0, 0.05), 62px 43px 80px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
}

.MuiDataGrid-columnsContainer {
  background-color: #0e8f99;
  color: #fff;
}

.MuiTypography-root {
  background-color: #fff !important;
}

.lineStyle {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  margin-bottom: 20px;
  color: #0e8f99;
  padding-bottom: 0.75rem;
  border-bottom-width: 4px;
  border: 1px solid #0e8f99;
  position: relative;
}

.lineStyle::before {
  content: " ";
  position: absolute;
  width: 50px;
  height: 10px;
  border-radius: 0.375rem;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  background: #0e8f99;
}

/* Myorder styles end */
