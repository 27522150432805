@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    scroll-behavior: smooth;
  }

  body {
    font-family: "Poppins", sans-serif;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .quantityBtn {
    @apply outline-none px-3 md:px-4 py-1 text-white bg-secondaryDark opacity-75 hover:opacity-100 transition-opacity duration-300;
  }

  .commonBtnStyle {
    @apply text-white rounded-md text-xs opacity-90 hover:opacity-100 transition-all duration-300 outline-none;
  }

  .headingStyle {
    @apply w-fit mx-auto mb-5 text-2xl md:text-3xl text-secondaryDark font-semibold pb-3 border-b-4 border-secondaryDark relative before:content-[''] before:absolute before:w-[50px] before:h-[10px] before:rounded-md before:bg-secondaryDark before:-bottom-[7px] before:left-[50%]  before:-translate-x-[50%];
  }

  /* .headingStylesDiv {
    @apply w-10 h-3 rounded-md bg-secondaryDark absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1.5;
  } */

  .productsLayoutStyle {
    @apply flex justify-center items-center flex-wrap px-8 md:px-24 gap-5 mt-10;
  }

  .filterHeadingStyle {
    @apply text-secondaryDark font-semibold tracking-wide;
  }

  .profileDivStyle {
    @apply flex flex-col w-full h-screen justify-center items-center;
  }

  .profileTitle {
    @apply text-xl text-slate-600 font-medium;
  }

  .profileValue {
    @apply text-base text-slate-500;
  }

  .profileLinkBtnStyle {
    @apply translate-y-5  py-2 px-10 text-white  rounded-md transition-all duration-500 hover:scale-105 opacity-80 hover:opacity-100;
  }

  .paymentInputFieldDivStyle {
    @apply bg-primaryBlue rounded-lg  w-full flex justify-start items-center;
  }

  .paymentInputFieldIconStyle {
    @apply text-xl text-white mx-2;
  }

  .paymentInputFieldStyle {
    @apply bg-white  px-3 py-2 outline-none border-2 w-full;
  }

  .slideableBtnStyles {
    @apply w-full outline-none text-center overflow-hidden relative z-10 before:content-[''] before:bg-secondaryDark before:w-1/2 before:absolute before:h-full before:top-0 before:left-0 before:-z-10 before:transition-all before:duration-500 hover:before:w-0 hover:before:opacity-0 shadow-md hover:shadow-lg hover:after:w-0 hover:after:opacity-0 after:content-[''] after:bg-secondaryDark after:w-1/2 after:absolute after:h-full  after:top-0 after:right-0 after:-z-10 after:transition-all after:duration-500 py-2 px-3 text-white rounded-md hover:scale-105 transition-all duration-500 bg-secondaryColor;
  }

  .heading {
    @apply text-xl font-semibold;
  }

  .headingData {
    @apply mt-3 ml-5;
  }

  .sidebarLinkStyles {
    @apply text-slate-600 hover:text-secondaryDark hover:scale-105 transition-all duration-500;
  }

  .summryBoxStyle {
    @apply text-white opacity-90 hover:opacity-100 transition-opacity duration-500 flex flex-col justify-center items-center font-medium text-base sm:text-xl md:text-2xl h-24 w-24 sm:h-32 sm:w-32 md:h-40 md:w-40  rounded-full;
  }

  .dashboardStyle {
    @apply h-auto w-full absolute z-10 bg-gray-50  tall:grid grid-cols-5;
  }

  .sidebarStyle {
    @apply col-span-1 border-r-2;
  }

  .dashboardRightBoxStyle {
    @apply col-span-4 h-auto w-full py-24;
  }
}
